import React from 'react'
import {Heading,SimpleGrid,Box,Text,HStack} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import MeinSeo from '../components/seo'

export default function Beitrag5(){

return(
  <motion.div
  initial={{opacity:0,scale:0.9,paddingBottom:"100px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:.7,ease:"easeInOut"}}
  >
  <MeinSeo title="Menschen, Stimmen, Erinnerungen" description='Fotodokumentation'/>
  <HStack  mb="15"  justifyContent="space-between">


<Box>
<StaticImage
  src="../images/emigration/2.jpg"
  alt="Emigration"
  layout='constrained'
  width={500}
 
/>

</Box>
<Box>
<StaticImage
  src="../images/emigration/1.jpg"
  alt="Emigration"
  layout='constrained'
  width={500}
 
/>

</Box>

</HStack>



<Heading mt="10" variant="Titel">
Menschen, Stimmen, Erinnerungen 
</Heading>
<SimpleGrid  columns={[1,1,2]} gap={5} >
  <Box mt="3" maxW={["100%","95%"]}>
<Text variant='solid'>
Nur wenige Spuren bezeugen heute den Verlust der jüdischen Gemeinden und ihrer Mitglieder. 

Vor allem den Jüngeren gelang die Flucht, die meisten Älteren wurden deportiert, in den Konzentrationslagern ermordet oder blieben verschollen. 
</Text>
<Text variant='solid'>
Sie alle wurden beraubt und ausgeplündert. Vereinzelt erinnern persönliche Gegenstände, Briefe und Erzählungen an individuelle Schicksale, die in der Galerie <a style={{color:'black',fontWeight:600}} href="https://www.markt1-bacharach.de" rel="noopener noreferrer" target="_blank"> &rarr; Markt 1</a> im Streiflicht präsentiert werden. Ausgewählte Fragmente beleuchten die brutale Wendung der Lebensgeschichten. 
</Text>
<Text variant="solid">
Dokumentiert werden darüber hinaus die Namen und – sofern vorhanden – biografischen Angaben der Opfer der nationalsozialistischen Judenverfolgung aus dem Rhein-Hunsrück-Kreis, Oberwesel und Bacharach. Eine multimediale Installation verbindet Namen, Bilder, Zeugenzitate und eröffnet somit einen Raum zum Gedenken.
</Text>

</Box>
<Box>

  <Box mt="15px">
   <Heading fontWeight="600">Diskriminierung, Ausgrenzung,  Mord
   Animation der unvollständigen Opferliste
   </Heading> 
   <Link to="/verbote">
  <Text color="teal.500" _hover={{color:'blue:400'}} variant="solid">
    
  Bilder aus der Nazi-Zeit &amp; zur Dokumentation der Entrechtlichung der jüdischen Bevölkerung im NS-Staat &rarr; 
  </Text>
  </Link>
 
   
   <Link to="/gedenkliste">
  <Text color="teal.500" _hover={{color:'blue:400'}} variant="solid"> Unvollständige Liste der Verschleppten und Ermordeten &rarr;</Text>
  </Link>
 
  <Heading mt="30px" fontWeight="600">Flucht und Emigration</Heading> 
   <Link to="/emigration">
  <Text color="teal.500" _hover={{color:'blue:400'}} variant="solid"> 
  Dokumente zur Emigration &rarr;
  </Text>
  </Link>
  </Box>
  <Box>
  <motion.div
  initial={{opacity:0,scale:.7,paddingBottom:40}}
  animate={{opacity:1,scale:1,y:20}}
  transition={{duration:1,delay:.3}}
  >

  <Text pt="5" fontWeight="800" variant='solid'>
    Wenn Leo weg ist, wird es ein trauriges Leben bei uns werden.

  </Text>
 
  <Text pb="1.5" fontWeight="900" maxWidth={["300","300","400"]} lineHeight="1" fontSize={["3xl","4xl","5 xl"]}>Und wer macht dann das Heu?</Text>
  </motion.div>
  </Box>
  </Box>
  <Box textAlign="center">
    <StaticImage src="../images/bacharach/18.jpg" width={550} height={450} alt="Bacharacher Opfer"
    
      />
      <Link to="/bacharach">
      <Text py="2" _hover={{color:'blue.400'}} variant='utitel'> &rarr; Schicksale aus Bacharach</Text>
      </Link>
  </Box>
  <Box textAlign="center">
    <StaticImage  src="../images/solo/rio.jpg" width={550} height={450} alt="Gelungene Flucht"/>
    <Link to="/oberwesel">
    <Text py="2" _hover={{color:'blue.400'}} variant="utitel"> &rarr; Schicksale  Oberwesel</Text>
    </Link>
  </Box>
  
  </SimpleGrid>


</motion.div>
    )
}